module.exports = {
  usStreet: {
    "street": "street",
    "street2": "street2",
    "secondary": "secondary",
    "city": "city",
    "state": "state",
    "zipcode": "zipCode",
    "lastline": "lastLine",
    "addressee": "addressee",
    "urbanization": "urbanization",
    "match": "match",
    "format": "format",
    "candidates": "maxCandidates"
  },
  usAutocompletePro: {
    search: "search",
    selected: "selected",
    max_results: "maxResults",
    include_only_cities: "includeOnlyCities",
    include_only_states: "includeOnlyStates",
    include_only_zip_codes: "includeOnlyZIPCodes",
    exclude_states: "excludeStates",
    prefer_cities: "preferCities",
    prefer_states: "preferStates",
    prefer_zip_codes: "preferZIPCodes",
    prefer_ratio: "preferRatio",
    prefer_geolocation: "preferGeolocation",
    source: "source"
  },
  usZipcode: {
    "city": "city",
    "state": "state",
    "zipcode": "zipCode"
  },
  internationalStreet: {
    "country": "country",
    "freeform": "freeform",
    "address1": "address1",
    "address2": "address2",
    "address3": "address3",
    "address4": "address4",
    "organization": "organization",
    "locality": "locality",
    "administrative_area": "administrativeArea",
    "postal_code": "postalCode",
    "geocode": "geocode",
    "language": "language"
  },
  internationalAddressAutocomplete: {
    search: "search",
    country: "country",
    max_results: "maxResults",
    include_only_administrative_area: "includeOnlyAdministrativeArea",
    include_only_locality: "includeOnlyLocality",
    include_only_postal_code: "includeOnlyPostalCode"
  },
  usReverseGeo: {
    "latitude": "latitude",
    "longitude": "longitude",
    "source": "source"
  },
  usExtract: {
    html: "html",
    aggressive: "aggressive",
    addr_line_breaks: "addressesHaveLineBreaks",
    addr_per_line: "addressesPerLine"
  },
  usEnrichment: {
    include: "include",
    exclude: "exclude",
    dataset: "dataset",
    data_subset: "dataSubset"
  }
};